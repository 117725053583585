import { ActionReducerMap } from '@ngrx/store';
import { userReducer } from './user/reducer';
import { appReducer } from './app/reducer';
import { AppState } from './app.state';
import { mainReducer } from './main/main.reducer';
import { IMainState } from './main/main.model';
import { IUserConfigurationState } from './user-configuration/user-configuration.model';
import { userConfigurationReducer } from './user-configuration/user-configuration.reducer';
import { IAdvancedFilterStore } from './advanced-filter/advanced-filter.model';
import { advancedFilterReducer } from './advanced-filter/advanced-filter.reducer';
import { IUserSettingsState } from './settings/users/users.model';
import { userSettingsReducer } from './settings/users/users.reducer';
import { filterUserReducer, FilterUserState } from './filter/user/user.reducer';
import { filterFormReducer, FilterFormState } from './filter/form/form.reducer';
import { filterLogbookReducer, FilterLogbookState } from './filter/logbook/logbook.reducer';
import { pageHeaderReducer } from './page-header/page-header.reducer';
import { IPageHeaderState } from './page-header/page-header.model';
import { IRolesState } from './settings/roles/roles.model';
import { roleReducer } from './settings/roles/roles.reducer';
import { IAuditTrailState } from './audit-trail/audit-trail.model';
import { auditTrailReducer } from './audit-trail/audit-trail.reducer';
import { rightReducer } from './settings/rights/rights.reducer';
import { IRightsState } from './settings/rights/rights.model';
import { clientSettingsReducer } from './settings/client/client.reducer';
import { IClientSettingsState } from './settings/client/client.model';
import { logbookScopesReducer } from './scopes/logbook-scopes/logbook-scopes.reducer';
import { IFormScopesState, ILogbookScopesState } from './scopes/scopes.model';
import { formScopesReducer } from './scopes/form-scopes/form-scopes.reducer';
import { IWorkflowState } from './settings/workflows/workflows.model';
import { workflowReducer } from './settings/workflows/workflows.reducer';
import { reasonReducer } from './reason/reason.reducer';
import { IReasonState } from './reason/reason.model';
import { logbookMasterDataReducer } from './settings/logbook-master-data/logbook-master-data.reducer';
import { formMasterDataReducer } from './settings/form-master-data/form-master-data.reducer';
import { ILogbookMasterDataState } from './settings/logbook-master-data/logbook-master-data.model';
import { IFormMasterDataState } from './settings/form-master-data/form-master-data.model';
import { ILogbooksState } from './logbooks/logbooks.model';
import { logbooksReducer } from './logbooks/logbooks.reducer';
import { logbookVersionsReducer } from './logbook-versions/logbook-versions.reducer';
import { ILogbookVersionState } from './logbook-versions/logbook-versions.model';
import { homeReducer } from './home/home.reducer';
import { IHomeState } from './home/home.model';
import { logsFormEntriesReducer } from './my-tasks/logs-form-entries/logs-form-entries.reducer';
import { ILogsFormEntriesState } from './my-tasks/logs-form-entries/logs-form-entries.model';
import { logbookTasksReducer } from './my-tasks/logbook-tasks/logbook-tasks.reducer';
import { ILogbookTasksState } from './my-tasks/logbook-tasks/logbook-tasks.model';
import { formTasksReducer } from './my-tasks/form-tasks/form-tasks.reducer';
import { IFormTasksState } from './my-tasks/form-tasks/form-tasks.model';
import { masterDataTasksReducer } from './my-tasks/master-data-tasks/master-data-tasks.reducer';
import { IMasterDataTasksState } from './my-tasks/master-data-tasks/master-data-tasks.model';
import { IFormEntriesState } from './reports/form-entries/form-entries.model';
import { formEntriesReducer } from './reports/form-entries/form-entries.reducer';
import { IMyReportsState } from './reports/my-reports/my-reports.model';
import { myReportsReducer } from './reports/my-reports/my-reports.reducer';
import { IFormTemplatesState } from './reports/form-templates/form-templates.model';
import { formTemplatesReducer } from './reports/form-templates/form-templates.reducer';
import { logbookTemplatesReducer } from './reports/logbook-templates/logbook-templates.reducer';
import { logsReportsReducer } from './reports/logs-reports/logs-reports.reducer';
import { ILogsReportsState } from './reports/logs-reports/logs-reports.model';
import { ILogbookTemplatesState } from './reports/logbook-templates/logbook-templates.model';
import { IForgotPinState } from './forgot-pin/forgot-pin.model';
import { forgotPinReducer } from './forgot-pin/forgot-pin.reducer';
import { checkInUsersReducer } from './check-in/check-in.reducer';
import { ICheckInUsersState } from './check-in/check-in.model';
import { IFormsState } from './forms/forms.model';
import { formsReducer } from './forms/forms.reducer';
import { IActivityTypeState } from './settings/activity-type/activity-type.model';
import { activityTypeReducer } from './settings/activity-type/activity-type.reducer';
import { statesReducer } from './settings/states/states.reducer';
import { IStateStates } from './settings/states/states.model';

export interface IFilter {
  userFilter: FilterUserState;
  formFilter: FilterFormState;
  logbookFilter: FilterLogbookState;
}

export interface LogbookAppState extends AppState, IFilter {
  mainStore: IMainState;
  userConfigurationStore: IUserConfigurationState;
  advancedFilterStore: IAdvancedFilterStore;
  userSettingsStore: IUserSettingsState;
  pageHeaderStore: IPageHeaderState;
  roleStore: IRolesState;
  auditTrailStore: IAuditTrailState;
  rightStore: IRightsState;
  clientStore: IClientSettingsState;
  logbookScopeStore: ILogbookScopesState;
  formScopeStore: IFormScopesState;
  workflowStore: IWorkflowState;
  reasonStore: IReasonState;
  logbookMasterData: ILogbookMasterDataState;
  formMasterData: IFormMasterDataState;
  logbookSettingsStore: ILogbooksState;
  logbookVersionsStore: ILogbookVersionState;
  homeStore: IHomeState;
  logsFormEntriesStore: ILogsFormEntriesState;
  logbookTasksStore: ILogbookTasksState;
  formTasksStore: IFormTasksState;
  masterDataTasksStore: IMasterDataTasksState;
  formEntriesStore: IFormEntriesState;
  myReportsStore: IMyReportsState;
  formTemplatesStore: IFormTemplatesState;
  logbookTemplatesStore: ILogbookTemplatesState;
  logsReportsStore: ILogsReportsState;
  forgotPinStore: IForgotPinState;
  checkInUsersStore: ICheckInUsersState;
  formSettingsStore: IFormsState;
  activityTypeStore: IActivityTypeState;
  statesStore: IStateStates;
}

export const logbookAppReducer: ActionReducerMap<LogbookAppState> = {
  mainStore: mainReducer,
  user: userReducer,
  app: appReducer,
  userConfigurationStore: userConfigurationReducer,
  advancedFilterStore: advancedFilterReducer,
  userSettingsStore: userSettingsReducer,
  userFilter: filterUserReducer,
  formFilter: filterFormReducer,
  logbookFilter: filterLogbookReducer,
  pageHeaderStore: pageHeaderReducer,
  roleStore: roleReducer,
  auditTrailStore: auditTrailReducer,
  rightStore: rightReducer,
  clientStore: clientSettingsReducer,
  logbookScopeStore: logbookScopesReducer,
  formScopeStore: formScopesReducer,
  workflowStore: workflowReducer,
  reasonStore: reasonReducer,
  logbookMasterData: logbookMasterDataReducer,
  formMasterData: formMasterDataReducer,
  logbookSettingsStore: logbooksReducer,
  logbookVersionsStore: logbookVersionsReducer,
  homeStore: homeReducer,
  logsFormEntriesStore: logsFormEntriesReducer,
  logbookTasksStore: logbookTasksReducer,
  formTasksStore: formTasksReducer,
  masterDataTasksStore: masterDataTasksReducer,
  formEntriesStore: formEntriesReducer,
  myReportsStore: myReportsReducer,
  formTemplatesStore: formTemplatesReducer,
  logbookTemplatesStore: logbookTemplatesReducer,
  logsReportsStore: logsReportsReducer,
  forgotPinStore: forgotPinReducer,
  checkInUsersStore: checkInUsersReducer,
  formSettingsStore: formsReducer,
  activityTypeStore: activityTypeReducer,
  statesStore: statesReducer,
};
